<template>
  <div id="app">
    <Tabs></Tabs>
    <div class="content_box">
      <div class="content">
        <!-- 试卷名称 -->
        <div class="paper_title">
          <div @click="goPrev">
            {{ chapter_name }}&nbsp;>&nbsp;{{
              this.$route.params.router_name
            }}&nbsp;>&nbsp;
          </div>
          <p>{{ paper_info.paper_title }}</p>
        </div>
        <!-- 二维码/得分 -->
        <div class="card_box">
          <!-- 二维码 -->
          <Code></Code>
          <!-- 得分 -->
          <div class="score_box">
            <div class="score_top">
              {{ paper_info.paper_title }}
            </div>
            <!-- 试卷未批改状态 -->
            <div
              class="no_correct_box"
              v-if="code_status.code == 1 && code_status.data == null"
            >
              <img src="../../../../../assets/img/gaijuan.png" alt="" />
              <p>试卷已提交,等待阅卷老师批改</p>
              <button type="button" @click="sure">确定</button>
            </div>
            <div class="score_down">
              <div class="score_down_content">
                <span>本次考试得分</span>
                <div class="score">
                  <div>{{ paper_info.score | numFilter }}</div>
                  <div>分</div>
                </div>
                <div class="text">主观题不参与评分，请参照解析自我评分</div>
                <div class="score_info">
                  <div class="score_info_item">
                    <p>{{ paper_info.writed_count }}</p>
                    <div>已做题数</div>
                  </div>
                  <div class="score_info_item">
                    <p>{{ paper_info.current_duration }}</p>
                    <div>考试用时</div>
                  </div>
                  <div class="score_info_item">
                    <p>{{ paper_info.total_count }}</p>
                    <div>试卷总题数</div>
                  </div>
                </div>
                <ul class="topic_list">
                  <li>
                    <div>题型</div>
                    <div>答对</div>
                    <div>总题数</div>
                  </li>
                  <li v-for="item in qtype_list" :key="item.id">
                    <div>{{ item.name }}</div>
                    <div>{{ item.true_num }}</div>
                    <div>{{ item.total_num }}</div>
                  </li>
                </ul>
                <div class="btn_box">
                  <button type="button" @click="againTopic(paper_info)">
                    重新做题
                  </button>
                  <button type="button" @click="goAll(1)">查看全部解析</button>
                  <button type="button" @click="goAll(2)">错题解析</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  props: {},
  data() {
    return {
      paper_list: [], // 试卷列表
      paper_info: {}, //试卷名称
      code_status: {}, // code状态
      qtype_list: [], // 题型列表
      chapter_name: "", // 章节名字
      time_length: "", //考试用时
    };
  },
  computed: {},
  beforeCreate() {},
  filters: {
    numFilter(value) {
      // 保留整数
      // let realVal = parseFloat(value).toFixed(1);
      let realVal = parseInt(parseInt(value));
      return realVal;
    },
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.chapter_name = sessionStorage.getItem("chapterName");
    // 判断是否交卷
    let is_paper = this.$route.params.is_handpaper;
    let infoArr = this.$store.state.paper_info;
    if (is_paper == 1) {
      this.paper_info = infoArr;
      this.qtype_list = infoArr.qtype_list;
      this.paper_info.current_duration = this.formatSeconds(
        this.paper_info.current_duration
      );
      // console.log("stor=======", this.$store.state.paper_info);
    } else {
      this.getDetail();
    }
  },
  mounted() {
    // 判断 是已交卷 禁止后退
    if (this.$route.params.is_handpaper == 1) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, document.URL);
      });
    }
  },
  watch: {},
  methods: {
    //转化时分秒
    formatSeconds(value) {
      let result = parseInt(value);
      let h =
        Math.floor(result / 3600) < 10
          ? "" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "" + Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      if (h !== "0") res += `${h}时`;
      if (m !== "0") res += `${m}分`;
      // res += `${s}秒`;
      res = h <= 0 && m <= 0 ? "1分钟" : h>0 ? h*60 + m +'分钟':`${m}分钟`
      return res;
    },
    // 返回上一页
    goPrev() {
      this.$router.go(-1);
    },
    //   获取详情单
    getDetail() {
      let data = {
        chapter_category: this.$route.params.chapter_id,
        id: this.$route.params.id, // 试卷id
      };
      api.record_detail(data).then((res) => {
        this.code_status = res.data;
        if (parseInt(res.data.code) == 1) {
          // this.paper_list = res.data.data.list;
          // res.data.data.list.forEach((ele) => {
          //   // console.log("ele====", ele.score);
          //   // 字符串截取分数小数点后的字符
          //   function getCaption(obj) {
          //     var index = obj.lastIndexOf(".");
          //     obj = obj.substring(index + 1, obj.length);
          //     //  // console.log(obj);
          //     return obj;
          //   }
          //   let str = ele.score;
          //   // 判断小数点后,有小数保留一位,没有取整
          //   if (getCaption(str) != "00") {
          //     // ele.score = parseInt(ele.score).toFixed(2);
          //     ele.score = Math.floor(ele.score * 100) / 100;
          //   } else {
          //     ele.score = parseInt(parseInt(ele.score));
          //   }
          //   // console.log(getCaption(str));
          //   // console.log("ele.score", ele.score);
          // });

          this.paper_info = res.data.data;
          res.data.data.current_duration = this.formatSeconds(
            res.data.data.current_duration
          );
          this.qtype_list = res.data.data.qtype_list;
        }
        // console.log("试卷详情", res);
      });
    },
    // 重新做题
    againTopic(item) {
      // console.log(item);
      // 开始做题之前先清除本地做题数据
      sessionStorage.removeItem("user");
      this.$router.push({
        name: "Introduce",
        params: {
          nav_id: 9,
          chapter_id: this.$route.params.chapter_id,
          chapter_name: sessionStorage.getItem("chapterName"),
          paper_id: item.paper_id,
          paper_name: item.paper_title,
          router_name: "做题记录",
        },
      });
    },
    // 查看全部解析
    goAll(id) {
      if (id == 1) {
        // console.log(this.paper_info);
        this.$router.push({
          name: "Whole",
          params: {
            is_pageid: 9, // 选中导航id
            chapter_id: this.$route.params.chapter_id, // 章节id
            url: this.paper_info.cache_file, // json缓存文件地址
            id: this.paper_info.id, // 请求详情单用到的id
            type_id: id, // 1全部 2错题
          },
        });
        if (this.$route.params.is_handpaper == 1) {
          sessionStorage.setItem(
            "userAnswer",
            JSON.stringify(this.paper_info.user_answer)
          ); // 存储用户答案
        }
      } else {
        this.$router.push({
          name: "Wrong",
          params: {
            is_pageid: 9, // 选中导航id
            chapter_id: this.$route.params.chapter_id, // 章节id
            url: this.paper_info.cache_file, // json缓存文件地址
            id: this.paper_info.id, // 请求详情单用到的id
            type_id: id, // 1全部 2错题
          },
        });
        if (this.$route.params.is_handpaper == 1) {
          sessionStorage.setItem(
            "userAnswer",
            JSON.stringify(this.paper_info.user_answer)
          ); // 存储用户答案
        }
      }
    },
    // 试卷已提交未批改
    sure() {},
    // 查看错题解析
    // goWrong() {
    //   this.$router.push({
    //     name: "Whole",
    //     params: {
    //       is_pageid: 9, // 选中导航id
    //       chapter_id: this.$route.params.chapter_id, // 章节id
    //       url: this.paper_info.cache_file, // json缓存文件地址
    //       id: this.$route.params.id, // 请求详情单用到的id
    //     },
    //   });
    // },
  },
  components: {},
};
</script>

<style scoped lang="scss">
#app {
  // width: 100%;
  // background: #f5f5f5;
  // 内容区
  .content_box {
    width: 100%;
    // height: 100vh;
    background: #f5f5f5;
    // padding-bottom: 117px;
    // padding-bottom: 300px;
    .content {
      width: 1200px;
      margin: auto;
      overflow: hidden;
      // 试卷名称
      .paper_title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666;
        margin: 28px 0 24px;
        display: flex;
        div:nth-child(1) {
          cursor: pointer;
        }
      }
      // 二维码/得分
      .card_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 117px;
        .code_box {
          width: 310px;
          height: 354px;
          // background: #ffffff;
        }
        .score_box {
          display: flex;
          flex-flow: column;
          // justify-content: space-between;
          .score_top {
            width: 880px;
            height: 76px;
            background: #ffffff;
            margin-bottom: 10px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 76px;
            text-align: center;
          }
          // 试卷未批改状态
          .no_correct_box {
            width: 880px;
            height: 550px;
            background: #ffffff;
            display: flex;
            flex-flow: column;
            align-items: center;
            img {
              width: 286px;
              height: 224px;
              margin: 110px 0 29px;
            }
            p {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #999999;
              margin-bottom: 16px;
            }
            button {
              width: 200px;
              height: 46px;
              background: #3e7eff;
              border-radius: 6px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
          .score_down {
            width: 880px;
            max-height: 805px;
            background: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            .score_down_content {
              width: 820px;
              max-height: 745px;
              background: #fafbfd;
              border-radius: 10px;
              display: flex;
              flex-flow: column;
              align-items: center;
              margin: 30px 0;
              span {
                font-size: 22px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                margin-top: 40px;
              }
              .score {
                display: flex;
                align-items: flex-end;
                // background: skyblue;
                div:nth-child(1) {
                  font-size: 88px;
                  font-family: Pingfang Sc;
                  font-weight: bold;
                  color: #e46724;
                }
                div:nth-child(2) {
                  // height: 65px;
                  font-size: 20px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #e46724;
                  // background: pink;
                  margin-bottom: 25px;
                }
              }
              .text {
                width: 250px;
                height: 37px;
                background: url("../../../../../assets/img/fenshu.png")
                  no-repeat;
                background-size: 250px 37px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #e46724;
                line-height: 46px;
                text-align: center;
                margin-top: -20px;
              }
              .score_info {
                max-width: 660px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 36px 0 40px;
                // background: pink;
                .score_info_item {
                  min-width: 200px;
                  display: flex;
                  flex-flow: column;
                  align-items: center;
                  justify-content: center;
                  // background: pink;
                  p {
                    font-size: 38px;
                    font-family: DIN Alternate;
                    font-weight: bold;
                    color: #3e7eff;
                  }
                  div {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                  }
                }
                .score_info_item:nth-child(2) {
                  border-left: 1px solid #f1f1f1;
                  border-right: 1px solid #f1f1f1;
                }
              }
              .topic_list {
                width: 660px;
                max-height: 300px;
                li {
                  height: 50px;
                  display: flex;
                  align-items: center;
                  border-bottom: 1px dashed #e9ecf1;
                  div:nth-child(1) {
                    width: 112px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #222222;
                  }
                  div:nth-child(2) {
                    width: 48px;
                    text-align: center;
                    margin: 0 120px 0 263px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                  div:nth-child(3) {
                    width: 48px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }
                li:nth-child(1) {
                  background: #eef1f6;
                  div {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }
              }
              .btn_box {
                width: 660px;
                display: flex;
                justify-content: space-between;
                margin: 40px 0 30px;
                button {
                  width: 200px;
                  height: 44px;
                  // opacity: 0.12;
                  border-radius: 22px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  cursor: pointer;
                }
                button:nth-child(1) {
                  background: rgba(62, 126, 255, 0.2);
                  color: #3e7eff;
                }
                button:nth-child(2) {
                  color: #fff;
                  background: #e46724;
                }
                button:nth-child(3) {
                  color: #fff;
                  background: #3e7eff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
